import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer2 from '../../components/footer2/Footer2';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar3 from '../../components/Navbar3/Navbar3';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ProjectSection3 from '../../components/ProjectSection3/ProjectSection3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about.webp';
import Footer from '../../components/footer/Footer';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Web Design in Jaffna
                </title>
                <meta
                    name="description"
                    content="Start on a digital journey with web development in Jaffna, seamlessly blending creativity and technology."
                />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <Hero 
            heroslogan="Web development company in Jaffna"
            herotitle="Digital Evolution with Innovative Solutions" 
            herocontent="We specialize in developing exceptional websites, ensuring robust functionality and cutting-edge features to elevate your online presence."/>
            <About 
            aboutTitle="Welcome To Matrix Mantra"
            abimg={abimg} 
            service="Web Development" 
            place="Jaffna"
            aboutcontent="Embark on a digital journey with our web development expertise in Jaffna, seamlessly combining creativity and functionality. Elevate your online footprint with tailored solutions for success and innovation. Our substantial growth in web development in Jaffna and beyond speaks volumes about client satisfaction, underscoring our dedication to excellence and delivering successful project outcomes. Trust us to provide dynamic digital solutions personalized to your requirements, ensuring your brand shines in the competitive online arena."/>
            <ServiceSection />
            <CtaSection tagline="web development company in Jaffna."/>
            {/* <FunFact/> */}
            {/* <TeamSection/> */}
            <ProjectSection3 />
            <Testimonial />
            <BlogSection landingPage="web-development-jaffna" />
            {/* <PartnerSection /> */}
            <ContactInfo />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage;
