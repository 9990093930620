import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar/BlogSidebar.js';
import VideoModal from '../ModalVideo/VideoModal';

import blogs from '../../api/blogs';

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

const BlogList = (props) => {
    const sortedBlogs = [...blogs].sort((a, b) => b.order - a.order);
    return (
        <Fragment>
            <Helmet>
                <title>Latest News and Articles</title>
                <meta
                    name="description"
                    content="Contact us to inquire about any of our services or business proposals."
                />
                <link rel="canonical" href="https://matrixmantra.lk/blogs" />
            </Helmet>
            <section className="wpo-blog-pg-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                            <div className="wpo-blog-content">
                                {sortedBlogs.map((blog, bitem) => (
                                   
                                    <div
                                        className={`post  ${blog.blClass}`}
                                        key={bitem}
                                    >
                                        <div>
                                            <img src={blog.blogSingleImg} alt="" loading='lazy'/>
                                            {/* <VideoModal /> */}
                                        </div>
                                        <div className="entry-meta">
                                            <ul>
                                                <li>
                                                    <i className="fi flaticon-user"></i>{' '}
                                                    By{' '}
                                                    <Link
                                                        onClick={ClickHandler} 
                                                        // to={`/blog/${blog.slug}/${blog.id}`}
                                                        to={`/blog/${blog.slug}`}
                                                    >
                                                        {blog.authorTitle}
                                                    </Link>{' '}
                                                </li>
                                                <li>
                                                    <i className="fi flaticon-comment-white-oval-bubble"></i>{' '}
                                                    Comments {blog.comment}{' '}
                                                </li>
                                                <li>
                                                    <i className="fi flaticon-calendar"></i>{' '}
                                                    {blog.create_at}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="entry-details">
                                            <h3>
                                                <Link
                                                    onClick={ClickHandler}
                                                    // to={`/blog/${blog.slug}/${blog.id}`}
                                                    to={`/blog/${blog.slug}`}
                                                >
                                                    {blog.title}
                                                </Link>
                                            </h3>
                                            <p>{blog.dicsrip}</p>
                                            <Link
                                                onClick={ClickHandler}
                                                // to={`/blog/${blog.slug}/${blog.id}`}
                                                to={`/blog/${blog.slug}`}
                                                className="blog-read-more blog-read-more-btn"
                                            >
                                                READ MORE
                                            </Link>
                                        </div>
                                    </div>
                                   
                                ))}

                                {/* <div className="pagination-wrapper pagination-wrapper-left">
                                    <ul className="pg-pagination">
                                        <li>
                                            <Link
                                                to="/blog-left-sidebar"
                                                aria-label="Previous"
                                            >
                                                <i className="fi ti-angle-left"></i>
                                            </Link>
                                        </li>
                                        <li className="active">
                                            <Link to="/blog-left-sidebar">1</Link>
                                        </li>
                                        <li>
                                            <Link to="/blog-left-sidebar">2</Link>
                                        </li>
                                        <li>
                                            <Link to="/blog-left-sidebar">3</Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/blog-left-sidebar"
                                                aria-label="Next"
                                            >
                                                <i className="fi ti-angle-right"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        <BlogSidebar blLeft={props.blLeft} />
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default BlogList;






