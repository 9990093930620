// Webdesign.js
import React from 'react';
import AccordionFaq from './AccordionFaq';


const SocialMediaMarketingFaq = ({ serviceTitle }) => {
  const socialMediaMarketingContent = [
    // ... web design content items
    {
        title: "What is social media marketing (SMM)?",
        content: "Social media marketing is the use of social media platforms to connect with the audience, build brand awareness, drive website traffic, and increase sales. It involves creating and sharing content on social media networks to achieve marketing goals.",
    },

    {
      title: "Which social media platforms are commonly used for marketing?",
      content: "Popular social media platforms for marketing include Facebook, Instagram, Twitter, LinkedIn, Pinterest, and TikTok. The choice of platform depends on the target audience and business objectives.",
    },

    
    {
      title: "How can social media benefit my business?",
      content: "Social media can enhance brand visibility, engage with your audience, drive website traffic, and facilitate customer communication. It provides a platform for building a community around your brand.",
    },

    {
      title: "What is the importance of a social media strategy?",
      content: " A social media strategy specify goals, target audience, content plans, and engagement tactics. It ensures an effective approach to social media marketing and helping businesses achieve their objectives.",
    },

    {
      title: " How often should I post on social media?",
      content: "Posting frequency depends on the platform and audience. Consistency is key, but quality matters more than quantity. Analyze engagement metrics and adjust your posting schedule based on audience response.",
    },

    {
      title: "What is the role of paid advertising on social media?",
      content: "Paid advertising on social media allows businesses to reach a targeted audience, increase brand visibility, and enhance website visits. So the website/brand can gain valuable insights through measurable results",
    },


    {
      title: "How can I measure the success of my social media efforts?",
      content: "Key performance indicators (KPIs) for social media success include engagement metrics (likes, shares, comments), reach, clicks, conversions, and customer feedback. Use analytics tools to track and analyze these metrics.",
    },

    {
      title:"How can I handle negative comments or reviews on social media?",
      content: "Respond professionally and promptly to negative comments. Address concerns, offer solutions, and take the conversation offline if necessary. Transparency and proactive communication can help manage and reduce negative feedback",
    },

    {
      title: "How can I build a community on social media?",
      content: "Engage with your audience by responding to comments, asking questions, and encouraging user-generated content. Host contests, polls, and live sessions to increase interaction. Building a community involves creating a sense of shared interests.",
    },

    {
      title: "How can I stay updated on social media trends?",
      content: "Follow industry blogs, attend webinars, and subscribe to newsletters. Regularly check social media platforms for updates and new features. Collaborating with other professionals and attending conferences can also provide knowledge about emerging trends.",
    },



  ];

  return <AccordionFaq serviceTitle={serviceTitle} content={socialMediaMarketingContent} />;
};

export default SocialMediaMarketingFaq ;
