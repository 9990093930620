// Webdesign.js
import React from 'react';
import AccordionFaq from './AccordionFaq';


const WebdesignFaq = ({ serviceTitle }) => {
  const webDesignContent = [
    // ... web design content items
    {
        title: "What is the cost of web design services in Sri Lanka?",
        content:"The cost of web design services in Sri Lanka varies depending on the complexity and features of the website. Basic websites are typically more affordable, while intricate or e-commerce sites tend to be costlier. Customized solutions, as well as additional services like SEO and maintenance, can also influence the overall cost. To determine the exact pricing for your specific needs, it's advisable to consult with web design companies in Sri Lanka.",
      },

      {
        title:"How long does it take to design and launch a website in Sri Lanka?" ,
        content:"The time required to design and launch a website in Sri Lanka can vary significantly based on the project's complexity. Typically, a basic website may take anywhere from 2 to 4 weeks to design and develop. More complex websites, such as e-commerce platforms or those with advanced features, may extend the timeline to 2 to 4 months. It's important to note that factors like client feedback, content readiness, and the web design agency's workload can influence the project's duration."
      },

      {
        title:"What is the importance of responsive web design in Sri Lanka?" ,
        content:"Responsive web design is crucial in Sri Lanka due to the widespread use of mobile devices. With a significant portion of the population accessing the internet on smartphones and tablets, a responsive design ensures that websites adapt seamlessly to different screen sizes. This enhances user experience, reduces bounce rates, and improves SEO rankings. In Sri Lanka's competitive digital landscape, a mobile-friendly website is essential for reaching and engaging a broader audience, ultimately driving more traffic and conversions."
      },

      {
        title:"Do Sri Lankan web design companies provide e-commerce website design services?",
        content:"Yes, many Sri Lankan web design companies do provide e-commerce website design services. Given the growing demand for online shopping and business transactions, web designers in Sri Lanka are well-equipped to create custom e-commerce websites. These platforms enable businesses to sell products and services online, manage inventory, process payments securely, and provide an excellent shopping experience. E-commerce design services encompass everything from user-friendly interfaces to secure payment gateways, benefiting to businesses looking to establish a strong online retail presence."
      },

      {
        title:"What is the average maintenance cost for a website in Sri Lanka?", 
        content:"The average maintenance cost for a website in Sri Lanka can vary depending on the complexity and specific needs of the site. Typically, businesses can expect to budget a monthly or annual fee, ranging from LKR 5,000 to LKR 20,000 or more, for routine upkeep, security updates, content additions, and technical support. Complex websites with extensive functionalities may incur higher maintenance costs. It's advisable to discuss maintenance packages with your web design provider to determine the most suitable plan for your website's ongoing health and performance."
      },

      {
        title:"Is website security a priority in web design services in Sri Lanka?", 
        content:"Yes, website security is a top priority in web design services in Sri Lanka. As cybersecurity threats continue to evolve, web design companies place a strong emphasis on implementing robust security measures. These measures include SSL certificates for data encryption, secure payment gateways, firewalls, and routine security audits. Protecting sensitive customer data and ensuring the website's resilience against cyber threats are of utmost importance. Clients can have confidence that Sri Lankan web design services prioritize security to maintain the trust and safety of both businesses and their customers online."
      },

      {
        title:"What kind of support is offered after the website is live?", 
        content:"After a website is live, web design companies in Sri Lanka typically offer comprehensive post-launch support. This includes troubleshooting any issues that may arise, ensuring the website functions smoothly. They also provide regular updates to keep the site current, secure, and in line with evolving technology. Technical assistance is readily available to address questions and concerns. Many companies offer service level agreements (SLAs) outlining the specific support and maintenance services, giving clients peace of mind that their websites will continue to perform at their best."
      },

      {
        title:"What are web design services, and why are they important for businesses?", 
        content:"Web design services involve creating and maintaining websites, focusing on aesthetics and functionality. They are essential for businesses as they enhance online presence, attract and engage customers, and reflect professionalism. When you partner with experts like Matrix Mantra, your web design needs are in the hands of a trusted provider that understands how to create visually appealing, user-friendly websites to help your business thrive."
      },

      {
        title:"How can web design services benefit my business?", 
        content:"Web design services can benefit your business by creating a visually appealing and user-friendly website that can attract and retain visitors. A well-designed website can improve user experience, build trust, and ultimately drive more conversions. By choosing a provider with a proven track record of delivering effective web design solutions tailored to your business's needs, you can enhance your online presence and boost your business's success."
      },

      {
        title:"Can I update my website's content on my own after it's designed?", 
        content:"Yes, you can typically update your website's content independently after it's designed, especially if it's built on a content management system (CMS) like WordPress or Joomla. These systems provide user-friendly interfaces for adding, editing, and managing content without requiring in-depth technical knowledge. Web designers often offer training and support to ensure clients can confidently maintain their websites, which is particularly valuable for businesses looking to keep their online information current and relevant. This capability grants you flexibility and control over your site's content."
      },
  ];

  return <AccordionFaq serviceTitle={serviceTitle} content={webDesignContent} />;
};

export default WebdesignFaq ;
