// Seo.js
import React from 'react';
import AccordionFaq from './AccordionFaq';

const SeoFaq = ({ serviceTitle }) => {
  const seoContent = [
    // ... SEO content items
    {
      title:" What is SEO?", 
      content:"SEO, or Search Engine Optimization, involves strategic enhancements to a website to boost its search engine visibility. By optimizing content, keywords, and technical aspects, SEO aims to attract more relevant and higher-quality traffic. This practice enhances a site's online presence, ultimately leading to improved rankings and increased user engagement." 
      },

      {
        title:"Why is SEO important for a website?" ,
        content:"SEO is important for websites as it improves search engine rankings, enhancing visibility and increasing organic traffic. This increased visibility boosts credibility, user trust, and engagement. Ultimately, SEO plays a vital role in the success of a website by ensuring it's easily discoverable and appealing to both users and search engines."
      },

      {
        title:"What are the key elements of on-page SEO?" ,
        content:"On-page SEO focuses on optimizing individual web pages for search engines. Key elements include strategic keyword placement, high-quality and relevant content, proper utilization of HTML tags, creation of user-friendly URLs, and effective internal linking. These components collectively enhance a page's visibility and ranking on search engine results pages."
      },

      {
        title:"How does backlinking impact SEO?", 
        content:"Backlinks play a vital role in SEO by signaling to search engines the value of your content. These incoming links from other websites enhance your site's authority and contribute to higher search engine rankings. Quality backlinks are a key factor in establishing credibility and visibility on the web."
      },

      {
        title:"What is the difference between on-page and off-page SEO?" ,
        content:"On-page SEO centers on optimizing your website's content and structure, including keywords and HTML elements. Off-page SEO focuses on external strategies like link building and social media efforts to enhance your site's authority. Together, they improve search engine rankings and overall visibility for your website on the internet."
      },

      {
        title:"How can mobile-friendliness enhance SEO?" ,
        content:"Mobile-friendliness is a crucial factor for SEO. Having a mobile-responsive website enhances user experience, positively influencing search engine rankings. Ensuring your site works well on mobile devices is essential for reaching a wider audience and improving overall visibility in search engine results."
      },

      {
        title:"What is the significance of keywords in SEO?" ,
        content:"Keywords are words or phrases users enter into search engines. Properly incorporating relevant keywords in your content helps search engines understand your content's context and improves your chances of ranking higher in search engine results."
      },

      {
        title:"How often should I update my website content for SEO?" ,
        content:"Frequently updating your website content is important for SEO success. Regular updates signal to search engines that your site is active and contributing to improved rankings. Consistent, fresh content engages visitors and enhances the overall user experience, ultimately boosting your site's visibility and credibility."
      },

      {
        title:"What is the role of site speed in SEO?" ,
        content:"Site speed is crucial for SEO. Faster-loading websites provide a better user experience, and search engines prioritize them in rankings. Optimize your site's speed to enhance user satisfaction and improve its visibility on search engine results pages, ultimately contributing to the success of your website in the online landscape."
      },

      {
        title:"Can social media impact SEO?" ,
        content:"Social media indirectly impacts SEO by enhancing brand visibility, driving traffic, and generating backlinks. While social signals may not directly influence rankings, a strong social media presence plays an role in a comprehensive online strategy, contributing to brand recognition and creating opportunities for increased organic visibility on search engine results pages."
      },
  ];

  return <AccordionFaq serviceTitle={serviceTitle} content={seoContent} />;
};

export default SeoFaq;
