import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer2 from '../../components/footer2/Footer2';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar3 from '../../components/Navbar3/Navbar3';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ProjectSection3 from '../../components/ProjectSection3/ProjectSection3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about.webp';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Web Development in Sri Lanka
                </title>
                <meta
                    name="description"
                    content="Web Development in Sri Lanka"
                />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <Hero 
            herotitle="Grow your Web Development with Us" 
            herocontent=" We provide innovative web development services in Sri Lanka, creating visually appealing and functional websites tailored to businesses' unique needs."/>
            <About 
            abimg={abimg} 
            service="Web Development"
            place="Sri Lanka"
            aboutcontent="Step into our tech realm! We're a Web development company in Sri Lanka, driven by a passion for crafting exceptional digital solutions. Using the latest tech, we transform your ideas into powerful, customized reality. Join us on this exciting journey into the world of innovation and technology!"/>
            <ServiceSection />
            <CtaSection tagline="web development company in Sri Lanka."/>
            {/* <FunFact/> */}
            {/* <TeamSection/> */}
            <ProjectSection3 />
            <Testimonial />
            <BlogSection landingPage="web-development" />
            {/* <PartnerSection /> */}
            <ContactInfo />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage;
