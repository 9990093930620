import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import './style.css';
import { ReactComponent as CompanyIcon } from '../../images/mini-logo.svg';
// import { ReactComponent as CompanyIcon } from '../../images/ct-shape.png';

const Scrollbar = () => {
    return (
        <div className="col-lg-12">
            <div className="header-menu">
                <ul className="smothscroll">
                    <li>
                        <AnchorLink href="#scroll">
                            <i className="ti-arrow-up"></i>
                        </AnchorLink>
                        <WhatsAppWidget
                            CompanyIcon={CompanyIcon}
                            companyName={'Matrix Mantra'}
                            phoneNumber="+94703367168"
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Scrollbar;
