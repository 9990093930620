import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import MissionVission from '../../components/MissionVission/MissionVission';
import About4 from '../../components/about4/about4';
import Skill2 from '../../components/Skill2/Skill2';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import FunFact from '../../components/FunFact/FunFact';
import Footer2 from '../../components/footer2/Footer2';

const AboutPage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    We're all about Cognition, Innovation and Revolution |
                    Matrix Mantra
                </title>
                <meta
                    name="description"
                    content="We provide online solutions, including web design, SEO, and digital marketing, with expert designers, developers, and SEO specialists."
                />
                {/* <meta property="og:title" content="We're all about Cognition, Innovation and Revolution | Matrix Mantra" /> */}
                <link rel="canonical" href="https://matrixmantra.lk/about" />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <PageTitle pageTitle={'About Us'} pagesub={'About'} />
            {/* <MissionVission /> */}
            <About4 />
            <Skill2 />
            {/* <TeamSection tClass={'wpo-team-section-s3'}/> */}
            <Testimonial />
            {/* <FunFact fClass={'wpo-fun-fact-section-s2 pt-0'}/> */}
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default AboutPage;
