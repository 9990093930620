import React from 'react';
import shape from '../../images/ct-shape.png';
import './ContactInfo.css'

const ContactInfo = (props) => {
    return (
        <section className="contact-info-section section-padding">
            <div className="container">
                <div className="office-info">
                    <div className="row">
                        <div className="col col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="office-info-item">
                                <div className="office-info-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-place"></i>
                                    </div>
                                </div>
                                <div className="office-info-text">
                                    <p>
                                        No 77, Ramanathan Road, Jaffna Srilanka
                                    </p>
                                    <span>OFFICE ADDRESS</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="office-info-item active">
                                <div className="office-info-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-email"></i>
                                    </div>
                                </div>
                                <div className="office-info-text">
                                    <p>info@matrixmantra.lk</p>
                                    <p style={{ color: 'transparent' }}>
                                        matrixmantra@gmail.com
                                    </p>
                                    <span>EMAIL ADDRESS</span>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-4 col-lg-4 col-md-4 col-12">
                            <div className="office-info-item">
                                <div className="office-info-icon">
                                    <div className="icon">
                                        <i className="fi flaticon-phone-call"></i>
                                    </div>
                                </div>
                                <div className="office-info-text">
                                    <p>+94 70 336 7168</p>
                                    <p>+94 21 221 5812</p>
                                    <span>Call Us Today</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ct-shape">
                <img src={shape} alt="shape" />
            </div>
        </section>
    );
};

export default ContactInfo;
