import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Contactpage from '../../components/Contactpage/Contactpage';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.png';
import Footer2 from '../../components/footer2/Footer2';

const ContactPage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Contact us for any inquiries | Matrix Mantra</title>
                <meta
                    name="description"
                    content="Contact us to inquire about any of our services or business proposals."
                />
                <link rel="canonical" href="https://matrixmantra.lk/contact" />
            </Helmet>
            <Navbar3 Logo={Logo} topbarNone={'topbar-none'} />
            <PageTitle pageTitle={'Contact Us'} pagesub={'Contact'} />
            <Contactpage />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default ContactPage;
