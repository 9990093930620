// Webdesign.js
import React from 'react';
import AccordionFaq from './AccordionFaq';


const SoftwareDevelopmentFaq = ({ serviceTitle }) => {
  const softwareDevelopmentContent = [
    // ... web design content items
    {
        title: "What is software development?",
        content: "Software development is the process of designing, coding, testing, and maintaining software applications. It involves various stages, from defining requirements to deploying and maintaining the final product.",
    },

    {
      title: "What are the main programming languages used in software development?",
      content: "Common programming languages include Java, Python, JavaScript, C#, C++, Ruby, Swift, and Kotlin. The choice depends on factors such as project requirements, platform, and developer preferences.",
    },

    
    {
      title: "What is the difference between front-end and back-end development?",
      content: "Front-end development focuses on the user interface and user experience, while back-end development involves server-side logic, databases, and application functionality. Together, they create a full-stack development environment.",
    },

    {
      title: "What is the Agile development methodology?",
      content: "Agile is an iterative and flexible software development approach that emphasizes collaboration, customer feedback, and incremental progress. It involves breaking down projects into smaller tasks and delivering functional increments in short cycles.",
    },

    {
      title: "How does version control benefit software development?",
      content: "Version control systems like Git help track changes in code, facilitate collaboration among developers, and allow for the reverting of code to previous states. It ensures a stable codebase and efficient teamwork",
    },

    {
      title: "What is the significance of testing in software development?",
      content: "Testing ensures software quality by identifying and fixing bugs, validating functionality, and ensuring that the software meets specified requirements. It includes various testing levels such as unit testing, integration testing, and user acceptance testing.",
    },


    {
      title: "What is DevOps in software development?",
      content: "DevOps is a set of practices that aim to automate and optimize collaboration between development (Dev) and operations (Ops) teams. It focuses on continuous integration, continuous delivery, and efficient communication to enhance software development and deployment.",
    },

    {
      title:"How do software development frameworks benefit developers?",
      content: "Frameworks provide pre-built code structures and tools that simplify common development tasks. They promote code reusability, reduce development time, and help maintain consistency across projects. Examples include Django for Python and Ruby on Rails for Ruby.",
    },

    {
      title: "What is the role of cloud computing in software development?",
      content: "Cloud computing provides immediate access to computing resources, allowing developers to scale applications easily, store and retrieve data, and deploy software without the need for physical infrastructure. It enhances flexibility, scalability, and cost-efficiency..",
    },

    {
      title: "How can security be ensured in software development?",
      content: "Security measures in software development include regular code reviews, encryption of sensitive data, keeping software and dependencies up to date, implementing secure authentication and authorization practices, and conducting regular security audits. Security is a continuous process throughout the development life cycle.",
    },



  ];

  return <AccordionFaq serviceTitle={serviceTitle} content={softwareDevelopmentContent} />;
};

export default SoftwareDevelopmentFaq ;
