import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import hero1 from '../../images/slider/1.png';
import hero2 from '../../images/slider/4.png';

const settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    // draggable: false,
    autoplay: false,
    autoplaySpeed: 10000,
    fade: true,
};

const Hero = ({herocontent, herotitle, heroslogan}) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };
    return (
        <section className="hero hero-slider-wrapper hero-style-1">
            <div className="hero-slider">
                <Slider {...settings}>
                    <div className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-title-sub">
                                        <h5>{heroslogan}</h5>
                                    </div>
                                    {/* <div className="slide-title"> */}
                                        <h1>
                                            {herotitle}
                                        </h1>
                                    {/* </div> */}
                                    <div className="slide-subtitle">
                                        <p>
                                            {herocontent}
                                        </p>
                                    </div>
                                    <div className="btns">
                                        <Link
                                            to="/projects"
                                            onClick={ClickHandler}
                                            className="theme-btn"
                                        >
                                            Explore More
                                        </Link>
                                    </div>
                                    <div className="slider-pic">
                                        <img src={hero2} alt="" loading='lazy'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-title-sub">
                                        <h5>Trusted Digital Partner.</h5>
                                    </div>
                                    <div className="slide-title">
                                        <h2>
                                            Digital Solution For Your Business.
                                        </h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>
                                            We are rapidly thriving Information
                                            technology company in Sri Lanka.
                                        </p>
                                    </div>
                                    <div className="btns">
                                        <Link to="/about" className="theme-btn">
                                            Explore More
                                        </Link>
                                    </div>
                                    <div className="slider-pic">
                                        <img src={hero2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Slider>
            </div>
        </section>
    );
};

export default Hero;
