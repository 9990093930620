import React from 'react';
import { Link } from 'react-router-dom';
import abimg from '../../images/about/ab2.png';
import './about4.css'

const ClickHandler = (props) => {
    window.scrollTo(10, 0);
};

const h2Style = {
    fontSize: '30px',
    lineHeight: '50px'
    // You can add more style properties here if needed
  };

const About4 = (props) => {
    return (
        <section className="wpo-about-section-s4 section-padding pb-120">
            <div className="container">
                <div className="wpo-about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <h4>Welcome To Matrix Mantra</h4>
                                <h2 style={h2Style }>
                                    We Are Rapidly Thriving Information
                                    Technology Company In Sri Lanka.
                                </h2>
                                <p>
                                    We have a team of web designers, developers, SEO specialists and content writers who are excited to build innovative solutions for our clients. We specialized in web designing & development, mobile application development, digital/social media marketing, outsourcing and many more. Our reach expand beyond Sri Lanka, we have active clients in Germany, Australia, United Kingdom, Dubai, Netherlands and Switzerland. Additionaly, we have a partnership with UL IT Solutions in Germany and Nets Digital solutions UK.{' '}
                                </p>
                                <p>
                                    We are committed to staying at the forefront of technological advancements to ensure that our clients receive the most innovative solutions possible. Using the latest tools and frameworks, our team of web designers and developers specialized in up-to-date technologies such as React.js, Angular, Node.js and more to build responsive and dynamic websites that adapt seamlessly to various devices and user preferences.
                                    Additionally, our mobile application development team utilizes cutting-edge platforms like Flutter and React Native to create cross-platform applications that offer unparalleled performance and user experience. With a focus on scalability, security, and user-centric design principles, we strive to future-proof our solutions, enabling our clients to stay ahead in the competitive digital landscape.
                                </p>
                                {/* <div className="text-sub-wrap">
                                    <div className="text-sub-item">
                                        <i className="fi flaticon-tax"></i>
                                        <h5>We are rapidly thriving Information technology company in Sri Lanka.</h5>
                                    </div>
                                </div> */}
                                <Link
                                    onClick={ClickHandler}
                                    className="theme-btn"
                                    to="/services"
                                >
                                    Our Services
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <div className="inner-img">
                                        <img src={abimg} alt="" loading='lazy' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-ab"></div>
        </section>
    );
};

export default About4;
