// Webdesign.js
import React from 'react';
import AccordionFaq from './AccordionFaq';


const WebdevelopmentFaq = ({ serviceTitle }) => {
  const webDesignContent = [
    // ... web design content items
    {
        title: "What is web development?",
        content: "Web development refers to the process of building and maintaining websites or web applications. It involves tasks such as web design, content creation, client-side/server-side scripting, and network security configuration.",
    },

    {
      title: "What is the difference between front-end and back-end development?",
      content: "Front-end development focuses on the user interface and user experience, dealing with the presentation and interaction of a website. Back-end development involves server-side logic, databases, and application functionality that users don't see but are important for the site's operation.",
    },

    
    {
      title: "What programming languages are commonly used in web development?",
      content: "Front-end development uses HTML, CSS, and JavaScript to create the user interface. Back-end development uses languages like Python, Ruby, PHP, Java, and JavaScript (Node.js) for server-side logic, database operations, and application functionality. Each set of languages plays an important role in building a complete web application.",
    },

    {
      title: "What is the role of frameworks and libraries in web development?",
      content: "Frameworks and libraries provide pre-written code and tools to enhance the development process. Frameworks (e.g., React, Angular) offer a structure for building applications, while libraries (e.g., jQuery) provide pre-built functions to simplify common tasks.",
    },

    {
      title: "What is the difference between HTTP and HTTPS?",
      content: "HTTP (Hypertext Transfer Protocol) is insecure, while HTTPS (Hypertext Transfer Protocol Secure) encrypts data transmitted between the browser and the server, providing a secure connection. It is essential for handling sensitive information such as passwords and payment details.",
    },

    {
      title: "What is version control, and why is it important in web development?",
      content: "Version control systems (e.g., Git) track changes in code, allowing multiple developers to collaborate efficiently. It provides a history of changes, helps resolve conflicts, and ensures a stable codebase.",
    },


    {
      title: "What is the significance of SEO in web development?",
      content: "Search Engine Optimization (SEO) is important for improving a website's visibility on search engines. Web developers optimize site structure, content, and performance to enhance search engine rankings and increase organic traffic.",
    },

    {
      title: "What is the importance of testing in web development?",
      content: "Testing ensures that a website or application functions correctly and meets user expectations. It includes various types of testing, such as unit testing, integration testing, and user acceptance testing, to identify and fix issues.",
    },

    {
      title: "How can I enhance the security of my web application?",
      content: "Security measures include validating user input, using HTTPS, securing databases, implementing proper authentication and authorization, keeping software and plugins up to date, and conducting regular security audits.",
    },

    {
      title: "What is the significance of cross-browser compatibility in web development?",
      content: "Cross-browser compatibility ensures that a website or application functions consistently across different web browsers. Web developers must test their code to guarantee a smooth user experience for visitors using various browsers like Chrome, Firefox, Safari, and Internet Explorer.",
    },



  ];

  return <AccordionFaq serviceTitle={serviceTitle} content={webDesignContent} />;
};

export default WebdevelopmentFaq ;
