// Seo.js
import React from 'react';
import AccordionFaq from './AccordionFaq';

const ITOutsourcingFaq = ({ serviceTitle }) => {
  const ITOutsourcingContent = [
    // ... SEO content items
    {
      title:"Do you sign NDAs?", 
      content:"Yes, we sign Non-Disclosure Agreements (NDAs) to ensure the confidentiality and security of your sensitive information. We take data protection seriously and are committed to maintaining the highest standards of confidentiality." 
      },

      {
        title: "What is your payment term structure?",
        content: "We offer flexible payment terms tailored to meet your specific project requirements. Typically, our payment terms include an advance payment, which is a percentage (usually 15%-20%) of the total project cost required upfront to initiate the project. We also offer milestone payments, where payments are made at predefined project milestones or upon completion of specific deliverables. Alternatively, we can bill on an hourly basis for projects that require ongoing maintenance, support, or a flexible scope. We are flexible and open to discussing the most suitable payment method for your project needs. Our goal is to ensure transparency and financial comfort throughout our collaboration."
      },
        
      {
        title:"Are you a registered company in Sri Lanka?" ,
        content:"Yes, Matrix Mantra is a registered company under the Registrar of Companies in Sri Lanka. We comply with all local regulations and operate with full legal authorization."
      },

      {
        title:"Can you integrate third-party services and APIs?", 
        content:"Yes, we have extensive experience in integrating third-party services and APIs. Whether you need payment gateways, social media integrations, or other third-party services, we can seamlessly integrate them into your website or application."
      },

      {
        title:"How do you ensure projects stay on budget?" ,
        content:"We ensure projects stay on budget through careful planning and regular monitoring. We are responsible for our quote and if cost exceeds we will bear that amount."
      },

      {
        title:"Do you provide replacements if your hired individuals resign?" ,
        content:"Yes, we understand the importance of trust and reliability when hiring our professionals. In the event of individual leaving job, we offer to provide suitable replacements to ensure minimal disruption to your project and business operations."
      },

      {
        title:"Do you provide training for managing and updating the website?" ,
        content:"Yes, we provide training sessions for your team to manage and update the website, enabling you to make updates and changes without needing extensive technical knowledge."
      },

      {
        title:"How do you handle updates during the development process?" ,
        content:"We understand that project requirements can evolve. We follow an agile development methodology, allowing for flexibility and iterative updates throughout the development process. We work closely with you to accommodate any changes or updates."
      },

      {
        title:"Can you help with digital marketing and SEO?" ,
        content:"Yes, in addition to development services, we offer digital marketing and SEO services to help increase your online visibility and drive traffic to your website. Our SEO experts can optimize your site for search engines and develop a comprehensive digital marketing strategy."
      },

      {
        title:" Do you offer post-launch support and maintenance?" ,
        content:"Yes, we offer comprehensive post-launch support and maintenance services. This includes regular updates, bug fixes, performance optimization, and security checks to ensure your website or application remains up-to-date and secure."
      },
  ];

  return <AccordionFaq serviceTitle={serviceTitle} content={ITOutsourcingContent} />;
};

export default ITOutsourcingFaq;
