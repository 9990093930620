// import React from 'react';
// import Projectwhole from '../../api/projectwhole';
// import { useParams } from 'react-router-dom';
// import './ProjectDescription.css'

// const ProjectDescription = (props) => {
//     const { id } = useParams();
//     const projectDetails = Projectwhole.find((item) => item.Id === id);

//     return (
//         <div className="row projectwhole">
//             {/* Project Description in col-8 space */}
//             <div className="col-lg-8 col-md-8 col-sm-12">
//                 <div className="wpo-project-single-item project-description">
//                     <div className="wpo-project-single-title">
//                         <h3>{projectDetails.title} Project</h3>
//                     </div>
//                     <p>
//                         {projectDetails.description}
//                     </p>
//                     {/* Add any other project description content as needed */}
//                 </div>
//             </div>

//             {/* Project Details in col-4 space */}
//             <div className="col-lg-4 col-md-4 col-sm-12 project-details">
//                 <div className="project-details-list">
//                     <ul>
//                         <li>Client:<br/> <span>{projectDetails.client}</span></li>
//                         <li>Category:<br/> <span>{projectDetails.subTitle}</span></li>
//                         <li>Date:<br/> <span>{projectDetails.date}</span></li>
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default ProjectDescription;

import React from 'react';
import Projectwhole from '../../api/projectwhole';
import { useParams } from 'react-router-dom';
import './ProjectDescription.css';

const ProjectDescription = (props) => {
    // const { id } = useParams();
    // const projectDetails = Projectwhole.find((item) => item.Id === id);

    const { slug } = useParams(); // Use slug instead of id
    
    // Find project by slug
    const projectDetails = Projectwhole.find((item) => item.slug === slug);

    return (
        <div className="row projectwhole">
            {/* Project Description and Details in col-12 space */}
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="wpo-project-single-item project-description">
                    <div className="row">
                        {/* Project Description in col-6 space */}
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="wpo-project-single-title">
                                <h3 style={{fontWeight:600}}>{projectDetails.title}</h3>
                            </div>
                            <p>
                                {projectDetails.description}
                            </p>
                           
                            {/* Add any other project description content as needed */}
                        </div>

                      
                        {/* <div className="col-lg-1 with-border">

                        </div> */}
                       
                        <div className="col-lg-3 col-md-6 col-sm-12 project-details">
                            <div className="project-details-list">
                                <ul>
                                    <li>Country<br/> <span>{projectDetails.country}</span></li>
                                    <li>Category<br/> <span>{projectDetails.subTitle}</span></li>
                                    <li>Date<br/> <span>{projectDetails.date}</span></li>
                                    
                            
                                </ul>
                                <a href={projectDetails.purl} className="go-to-website-button" target="_blank">Visit Website</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDescription;


