import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer2 from '../../components/footer2/Footer2';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar3 from '../../components/Navbar3/Navbar3';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ProjectSection3 from '../../components/ProjectSection3/ProjectSection3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about.webp';
import Footer from '../../components/footer/Footer';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    SEO in Jaffna
                </title>
                <meta
                    name="description"
                    content="Explore the digital world with SEO in Jaffna, combining creativity and technology. Boost online visibility using customized strategies for success and innovation in search engine optimization."
                />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <Hero 
            heroslogan="SEO company in Jaffna"
            herotitle="Unlock the Power of SEO Strategies" 
            herocontent="We enhance website visibility, drive organic traffic, and boost search engine rankings, ensuring your brand stands out in the digital landscape."/>
            <About 
            aboutTitle="Welcome To Matrix Mantra"
            abimg={abimg} 
            service="SEO" 
            place="Jaffna"
            aboutcontent="Embark on an SEO journey with us, where we transform concepts into successful online strategies. Our growth in Jaffna and beyond, specifically in SEO, mirrors client satisfaction—a testament to our commitment to excellence in SEO services. Collaborate with us for tailored digital solutions, ensuring your brand excels in the competitive online landscape with our specialized SEO services in Jaffna."/>
            <ServiceSection />
            <CtaSection tagline="SEO company in Jaffna."/>
            {/* <FunFact/> */}
            {/* <TeamSection/> */}
            <ProjectSection3 />
            <Testimonial />
            <BlogSection landingPage="seo-jaffna" />
            {/* <PartnerSection /> */}
            <ContactInfo />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage;
