import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ts1 from '../../images/testimonial/img-1.jpg';
import ts2 from '../../images/testimonial/img-2.jpg';
import ts3 from '../../images/testimonial/img-3.jpg';
import VideoModal from '../ModalVideo/VideoModal';
import SectionTitle from '../SectionTitle/SectionTitle';

const testimonial = [
    // {
    //     id: '01',
    //     tImg: ts1,
    //     Des: 'I just wanted to share a quick note and let you know that you guys do a  really good job. I’m glad I decided to work with you. It’s really great how easy. One way to categorize the activities is in terms.',
    //     Title: 'Cathi Falcon',
    //     Sub: 'Founder',
    // },
    // {
    //     id: '02',
    //     tImg: ts2,
    //     Des: 'Such a fantastic team, I am glad that I got a chance to work with MxMA team. They were super goal oriented and always thinking beyond.',
    //     Title: 'Lucy Mble',
    //     Sub: 'Senior Director',
    // },

    {
        id: '01',
        tImg: ts1,
        Des: 'I urgently needed a appointment booking website for my repair shop, "Gilbert Vincent Shop," in Switzerland. Looked for affordable web design company in Sri Lanka Other companies couldn\'t deliver, but Matrix Mantra did it quickly and at a fair price. I\'m super happy with their service and the website. Totally satisfied! 👏',
        Title: 'Tony',
        Sub: 'Owner of Gilbert Vincent Shop, Switzerland',
    },

    {
        id: '02',
        tImg: ts2,
        Des: 'Matrix Mantra\'s software solution transformed my online business! From managing inventory to processing orders, everything\'s smoother now. They really understand what businesses need. Highly recommend.',
        Title: 'Lucky',
        Sub: 'Director of LuckyMobile.lk , Jaffna',
    },

    {
        id: '03',
        tImg: ts3,
        Des: 'I\'m extremely satisfied with Matrix Mantra. Their web design and SEO services have significantly enhanced our online presence. The website they created captures our brand perfectly, and their SEO efforts are driving  results. I highly recommend for their expertise and dedication.',
        Title: 'Kumar vasantha',
        Sub: ' Founder of TASC Accountants & Business Advisors,Melbourne',
        
    },

    {
        id: '04',
        tImg: ts3,
        Des: 'I wanted to make websites for schools and kovils in our community. I looked for a high quality and affordable web design company in Sri Lanka. After some research I chose Matrix Mantra. They have a friendly team who always answer calls, no matter the time. I really recommend Matrix Mantra for anyone looking for a great web design service.',
        Title: 'Uthaya Kumar',
        Sub: ' Technical Support Engineer, Germany',
        
    },
    
];

const Testimonial = () => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <section className="wpo-testimonial-section section-padding">
            <div className="container">
                <div className="wpo-testimonial-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            <SectionTitle
                                subTitle={'CLIENT TESTIMONIAL'}
                                Title={'Best Word from Our Clients'}
                            />
                            <div className="wpo-testimonial-items">
                                <Slider
                                    asNavFor={nav2}
                                    ref={(slider1) => setNav1(slider1)}
                                    dots={true}
                                    arrows={false}
                                >
                                    {testimonial.map((tesmnl, tsm) => (
                                        <div
                                            className="wpo-testimonial-item"
                                            key={tsm}
                                        >
                                            <div className="wpo-testimonial-text">
                                                <p>“{tesmnl.Des}”</p>
                                                <div className="wpo-testimonial-text-btm">
                                                    <span>{tesmnl.Sub}</span>
                                                    <h3>{tesmnl.Title}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        {/* <div className="col-lg-5 offset-lg-1">
                            <div className="testimonial-right-side">
                                <Slider
                                    asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={1}
                                    fade={true}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                >
                                    {
                                        testimonial.map((tesmnl, tsm) => (
                                            <div className="testimonial-img" key={tsm}>
                                                <img src={tesmnl.tImg} alt="" />
                                            </div>
                                        ))
                                    }

                                </Slider>
                                <div className="video-holder">
                                    <VideoModal />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
