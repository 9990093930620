
// // Accordion.js
// import React from 'react';
// import AccordionItem from './AccordionItem';

// const AccordionFaq = ({ serviceTitle, content }) => {
//   return (
//     <div className="accordion-container">
//       {content.map((item, index) => (
//         <AccordionItem key={index} title={item.title} content={item.content} />
//       ))}
//     </div>
//   );
// };

// export default AccordionFaq;

// AccordionFaq.js
import React from 'react';
import AccordionItem from './AccordionItem';

const AccordionFaq = ({ serviceTitle, content }) => {
  const splitContent = splitArrayIntoColumns(content, 2); // Assuming you want 2 columns

  return (
    <div className="accordion-container">
      {splitContent.map((column, columnIndex) => (
        <div key={columnIndex} className="accordion-column">
          {column.map((item, index) => (
            <AccordionItem key={index} title={item.title} content={item.content} />
          ))}
        </div>
      ))}
    </div>
  );
};

// Helper function to split an array into specified number of columns
function splitArrayIntoColumns(arr, columns) {
  const result = [];
  const itemsPerColumn = Math.ceil(arr.length / columns);

  for (let i = 0; i < columns; i++) {
    const startIndex = i * itemsPerColumn;
    const endIndex = startIndex + itemsPerColumn;
    const columnItems = arr.slice(startIndex, endIndex);
    result.push(columnItems);
  }

  return result;
}

export default AccordionFaq;








