import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import Footer2 from '../../components/footer2/Footer2';

const ProjectPage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Successful projects and our happy clients | Matrix Mantra
                </title>
                <meta
                    name="description"
                    content="We have a very energetic young talents who keep our clients happy by delivering beyond the expectations and delivering on time"
                />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <PageTitle pageTitle={'Projects'} pagesub={'Project'} />
            <ProjectSection pClass={'border-bt'} />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default ProjectPage;
