import '../../sass/page/_blog-All-Pages.scss';
import { Helmet } from 'react-helmet';

const Typography = () => {
    return (
        <div className="wpo-blog-all-pages">
            <div className="wpo-div-section">
                <p>
                    Typography is a crucial part of creating a great user experience for any website. When done well, it can make content easier to read, keep users engaged, and even strengthen your brand identity. In web design in Sri Lanka, paying attention to typography can set your site apart and improve how users interact with your content. Below are six simple tips to improve typography and create a more user-friendly website.
                </p>
                <h3>Choose Easy-to-Read Fonts</h3>
                <p>
                    Choosing the right font is one of the most important decisions in web design. For body text, it’s best to use fonts that are easy to read on all devices, especially mobile phones. Sans-serif fonts, like Arial or Helvetica, are great for readability due to their clean appearance. For headings, you might want to use a serif font like Times New Roman to add variety. Whatever fonts you pick, make sure they are legible at different sizes.
                </p>
                <h3>Keep a Clear Hierarchy</h3>
                <p>
                    A clear hierarchy helps users navigate your site easily. In web design in Sri Lanka, using different font sizes and weights for headings and body text can guide users through the content. For example, using a bold, larger font for main headings (H1) and slightly smaller fonts for subheadings (H2, H3) helps users scan the page quickly. A strong hierarchy makes the content more organized and user-friendly.
                </p>
                <h3>Use Proper Line Length</h3>
                <p>
                    The length of your text lines has a big impact on readability. If your lines are too long, it’s harder for users to follow the text. If they are too short, it disrupts the flow of reading. The best line length is between 50 to 75 characters. When working on web design in Sri Lanka, make sure your line length is optimized for screens, so users can comfortably read your content.
                </p>
                <h3>Pay Attention to Line Spacing</h3>
                <p>
                    Line spacing, or the space between lines of text, is another important factor. Spacing that is too tight can make the text hard to read, while too much space can make it look disorganized. A good rule is to set line spacing to about 1.5 times the font size. This creates enough space for users to clearly see each line of text. Good line spacing can make your web design in Sri Lanka more readable and user-friendly.
                </p>
                <h3>Limit the Number of Fonts</h3>
                <p>
                    Consistency is key in web design. Using too many fonts can make your website look messy and confusing. Instead, stick to two or three fonts across your site. For example, use one font for headings and another for body text. This will make your website feel more professional and cohesive, an essential part of good web design in Sri Lanka.
                </p>
                <h3>Ensure Good Contrast</h3>
                <p>
                    Text contrast is crucial for readability. Dark text on a light background is usually the best choice, but light text on a dark background can work if the contrast is strong enough. Keep in mind that contrast affects accessibility too. When doing web design in Sri Lanka, ensure your text is easy to read for all users, including those with visual impairments.
                </p>
                
                <h3>Conclusion</h3>
                <p>
                    Improving typography is a simple but powerful way to enhance user experience on your website. By selecting readable fonts, maintaining a clear hierarchy, optimizing line length, ensuring proper line spacing, limiting fonts, and using good contrast, you can create a website that is both beautiful and functional. These practices are especially important in web design in Sri Lanka, where user-friendly, engaging websites are key to standing out. With the right typography, your website can offer a better experience for visitors and keep them coming back.
                </p>
                
            </div>
        </div>
    );
};

export default Typography;
