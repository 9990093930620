// AccordionItem.js
import React, { useState } from 'react';
import './Accordion.css';

const AccordionItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion-item ${isOpen ? 'open' : ''}`}>
      <div className={`accordion-title ${isOpen ? 'active' : ''}`}  onClick={toggleAccordion}>
        {title}
        <span className={`accordion-icon ${isOpen ? 'hidden' : ''}`}>&#9660;</span>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

export default AccordionItem;
