// import React from 'react';
// import Services from '../../api/service';
// import { Link } from 'react-router-dom';

// const ServiceSidebar = (props) => {
//     const SubmitHandler = (e) => {
//         e.preventDefault();
//     };

//     const ClickHandler = () => {
//         window.scrollTo(10, 0);
//     };

//     return (
//         <div className="col-lg-4 col-md-8">
//             <div className="blog-sidebar custom-sidebar" >
//                 {/* <div className="widget search-widget">
//                     <h3>Search Here</h3>
//                     <form onSubmit={SubmitHandler}>
//                         <div>
//                             <input
//                                 type="text"
//                                 className="form-control"
//                                 placeholder="Search Post.."
//                             />
//                             <button type="submit">
//                                 <i className="ti-search"></i>
//                             </button>
//                         </div>
//                     </form>
//                 </div> */}
//                 <div className="widget category-widget" style={{ backgroundColor: '#1A384C' }}>
//                     <h3 style={{ color: 'white' }}>Our Services</h3>
//                     <ul>
//                         {Services.slice(0, 6).map((service, Sitem) => (
//                             <li key={Sitem}>
//                                 <Link
//                                     onClick={ClickHandler}
//                                     to={`/service/${service.slug}/${service.Id}`}
//                                 >
//                                     {service.sTitle}
//                                 </Link>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//                 {/* <div className="wpo-contact-widget widget">
//                     <div className="wpo-contact-widget-inner">
//                         <h2>
//                             <Link onClick={ClickHandler} to="/contact">
//                                 Contact For Advertisment 270 x 310
//                             </Link>
//                         </h2>
//                     </div>
//                 </div> */}
//             </div>
//         </div>
//     );
// };

// export default ServiceSidebar;


import React from 'react';
import { Link } from 'react-router-dom';
import Services from '../../api/service';
import Projects from '../../api/project';
import about from '../../images/blog/about-widget.jpg';
import blogs from '../../api/blogs';
import "./sidebar.css";

const SubmitHandler = (e) => {
    e.preventDefault();
};

const ClickHandler = () => {
    window.scrollTo(10, 0);
};

const ServiceSidebar = (props) => {

     // Assuming blogs is an array of blog posts
     // Sort blogs by date in descending order to get the latest ones first
     const sortedBlogs = blogs.sort((a, b) => new Date(b.create_at) - new Date(a.create_at));
     // Take the first three blogs
     const displayedBlogs = sortedBlogs.slice(0, 9);;

    return (
        <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
            <div className="blog-sidebar">
                {/* <div className="widget about-widget">
                    <div className="img-holder">
                        <img src={about} alt="" />
                    </div>
                    <h4>Jenny Watson</h4>
                    <p>
                        Hi! beautiful people. I`m an authtor of this blog. Read
                        our post - stay with us
                    </p>
                    <div className="social">
                        <ul className="clearfix">
                            <li>
                                <Link
                                    onClick={ClickHandler}
                                    to="/blog-single/1"
                                >
                                    <i className="ti-facebook"></i>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={ClickHandler}
                                    to="/blog-single/1"
                                >
                                    <i className="ti-twitter-alt"></i>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={ClickHandler}
                                    to="/blog-single/1"
                                >
                                    <i className="ti-linkedin"></i>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    onClick={ClickHandler}
                                    to="/blog-single/1"
                                >
                                    <i className="ti-pinterest"></i>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="aw-shape"></div>
                </div> */}
                {/* <div className="widget search-widget">
                    <h3>Search Here</h3>
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search Post.."
                            />
                            <button type="submit">
                                <i className="ti-search"></i>
                            </button>
                        </div>
                    </form>
                </div> */}
                <div className="widget category-widget" style={{ backgroundColor: '#1A384C' }}>
                    <h3 style={{ color: 'white' }}>Our Services</h3>
                    <ul>
                        {Services.map((service, Sitem) => (
                            <li key={Sitem}>
                                <Link
                                    onClick={ClickHandler}
                                    to={`/service/${service.slug}`}
                                >
                                    {service.sTitle}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="widget recent-post-widget" style={{ backgroundColor: '#1A384C' }}>
                    <h3 style={{ color: 'white' }}>Related Posts</h3>
                    <div className="posts">
                        {displayedBlogs.map((blog, bl) => (
                            <div className="post" key={bl}>
                                <div className="img-holder">
                                    <img src={blog.screens} alt="" loading='lazy'/>
                                </div>
                                <div className="details">
                                    <h4>
                                    <Link
                                        onClick={ClickHandler}
                                        to={`/blog/${blog.slug}`}
                                        style={{ color: 'white', textDecoration: 'none' }} // Add this inline style
                                    >
                                        {blog.title}
                                    </Link>

                                    </h4>
                                    <span className="date">
                                        {blog.create_at}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Consulting
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Planning
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Marketing
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Strategy
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Finance
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Solution
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Corporate
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Idea
                            </Link>
                        </li>
                        <li>
                            <Link onClick={ClickHandler} to="/blog-single/1">
                                Market Reserch
                            </Link>
                        </li>
                    </ul>
                </div> */}
                {/* <div className="wpo-contact-widget widget">
                    <div className="wpo-contact-widget-inner">
                        <h2>
                            <Link onClick={ClickHandler} to="/contact">
                                Contact For Advertisment 270 x 310
                            </Link>
                        </h2>
                    </div>
                </div> */}

<div className="widget contact-widget " style={{ backgroundColor: '#1A384C' }}>
                    <h3 style={{ color: 'white' }}>Contact Us</h3>
                    <form onSubmit={SubmitHandler}>
                        <div className="form-group">
                            <label htmlFor="name">Your Name</label>
                            <input type="text" id="name" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Your Email</label>
                            <input type="email" id="email" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Your Phone Number</label>
                            <input type="tel" id="phone" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Your Message</label>
                            <textarea id="message" className="form-control" rows="8"></textarea>
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>

                <div className="widget detail-widget" style={{ backgroundColor: '#1A384C' }}>
<h3 style={{ color: 'white' }}>Have Any Questions</h3>
<div className="contact-info">
    <div className="info-item">
        <i className="ti-mobile"></i> {/* Assuming you have an icon library or class for the phone icon */}
        <span>
  <span class="hotline-text">Hotline:</span><br/>
  +94 70 336 7168
</span>

    </div>
    <div className="info-item">
        <i className="ti-email"></i> {/* Assuming you have an icon library or class for the email icon */}
        <span>Sent Email:<br/> info@matrixmantra.lk</span>
    </div>
</div>
</div>
          
            </div>
        </div>



    );
};

export default ServiceSidebar;
