import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer2 from '../../components/footer2/Footer2';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar3 from '../../components/Navbar3/Navbar3';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ProjectSection3 from '../../components/ProjectSection3/ProjectSection3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/Matrix_Mantra_About_Us.webp';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>Information Technology Solutions | Top IT Company in Sri Lanka</title>
                <meta name="description" content="Explore innovative IT solutions in Sri Lanka to transform your business with cutting-edge technology and tailored services." />
                <link rel="canonical" href="https://matrixmantra.lk/" />
            </Helmet>

            <Navbar3 topbarNone={'topbar-none'} />
            <Hero 
            heroslogan="We Create Breathtaking Digital Experiences."
            herotitle="Elevate Your IT Growth Here." 
            herocontent="As an professional IT company in Sri Lanka, we specialize in creating amazing websites, robust software applications and offer digital marketing solutions."
            />
            <About abimg={abimg} 
            service="Information Technology" 
            place="Sri Lanka"
            aboutcontent="We have a team of web designers, developers, SEO specialists and content writers who are excited to build innovative solutions for our clients. We specialized in web designing & development, mobile application development, digital/social media marketing, outsourcing and many more. Our reach expand beyond Sri Lanka, we have active clients in Germany, Australia, United Kingdom, and Switzerland. Additionaly, we have a partnership with UL IT Solutions in Germany."/>
            <ServiceSection />
            <CtaSection tagline="Information Technology company in Sri Lanka." />
            {/* <FunFact/> */}
            {/* <TeamSection/> */}
            <ProjectSection3 />
            <Testimonial />
            {/* <BlogSection /> */}
            <BlogSection landingPage="home" />
            {/* <PartnerSection /> */}
            <ContactInfo />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage;
