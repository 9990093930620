import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Navbar3 from '../../components/Navbar3/Navbar3';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { useParams } from 'react-router-dom';
import blogs from '../../api/blogs';
import BlogSingle from '../../components/BlogDetails/BlogSingle.js';
import Footer2 from '../../components/footer2/Footer2';

// const BlogDetails = () => {
//     const { id } = useParams();

//     const BlogDetails = blogs.find((item) => item.id === id);

//     return (
//         <Fragment>
//             <Helmet>
//                 <title>{`${BlogDetails.title} | Matrix Mantra`}</title>
//                 <meta name="description" content={BlogDetails.title} />
//             </Helmet>
//             <Navbar3 topbarNone={'topbar-none'} />
//             <PageTitle pageTitle={BlogDetails.title} pagesub={'Blog'} />
//             <BlogSingle />
//             <Footer2 />
//             <Scrollbar />
//         </Fragment>
//     );
// };
// export default BlogDetails;



const BlogDetails = () => {
    const { slug } = useParams();

    // Assuming blogs array has a 'slug' property
    const BlogDetails = blogs.find((item) => item.slug === slug);

    if (!BlogDetails) {
        // Handle the case where the blog post is not found
        return <p>Blog post not found!</p>;
    }

    return (
        <Fragment>
            <Helmet>
                <title>{`${BlogDetails.title} | Matrix Mantra`}</title>
                <meta name="description" content={BlogDetails.title} />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <PageTitle pageTitle={BlogDetails.title} pagesub={'Blog'} />
            <BlogSingle />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default BlogDetails;
