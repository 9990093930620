import React from 'react';
import ContactForm from '../ContactFrom/ContactForm';

const Contactpage = () => {
    return (
        <section className="wpo-contact-pg-section section-padding">

            <div className="container office-info">
                {/* <div className="wpo-contact-title">
                    <h2>Have Any Question?</h2>
                    <p>
                        It is a long established fact that a reader will
                        be distracted content of a page when looking.
                    </p>
                </div> */}
                <ContactForm />
            </div>

            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-place"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Address</h2>
                                            <a
                                                href="https://www.google.com/maps/place/MATRIX+MANTRA+PVT(Ltd)/@9.686098,80.017031,15z/data=!4m5!3m4!1s0x0:0x92bfb9e8da614d79!8m2!3d9.6860983!4d80.017031?hl=en"
                                                target={'_blank'}
                                                rel="noreferrer"
                                            >
                                                No 77 , Ramanathan Road , Jaffna
                                                Srilanka
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Email Us</h2>
                                            <a href="mailto:info@matrixmantra.lk">
                                                info@matrixmantra.lk
                                            </a>
                                            <p style={{ color: 'transparent' }}>
                                                matrixmantra.lk@gmail.com
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Call Now</h2>
                                            <a href="tel:+94703367168">
                                                +94 70 336 7168
                                            </a>
                                            <p style={{ color: 'transparent' }}>
                                                +94 21 221 5812
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15731.76300003096!2d80.017031!3d9.6860983!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x92bfb9e8da614d79!2sMATRIX%20MANTRA%20PVT(Ltd)!5e0!3m2!1sen!2slk!4v1666987898064!5m2!1sen!2slk"></iframe>
                </div>
            </section>
        </section>
    );
};

export default Contactpage;
