import pimg1 from '../images/project/img-1.jpg';
import pimg2 from '../images/project/img-2.jpg';
import pimg3 from '../images/project/img-3.jpg';
import pimg4 from '../images/project/img-31.jpg';
import pimg5 from '../images/project/img-4.jpg';
import pimg6 from '../images/project/img-5.jpg';
import pimg7 from '../images/project/img-6.jpg';
import pimg8 from '../images/project/img-7.jpg';
import pimg9 from '../images/project/img-8.jpg';
import pimg10 from '../images/project/1.png';
import pimg11 from '../images/project/2.png';
import pimg12 from '../images/project/3.png';
import pimg13 from '../images/project/4.png';
import lucky from '../images/project/Lucky1.webp';
// im wisefin from '../images/project/Wisefin1.webp';
import tasc from '../images/project/tasc1.webp';
import murrindindi from '../images/project/murrindindi_executive_retreat.webp';
// import unibay from '../images/project/Unibay1.webp';
import luckymobile from '../images/project/Lucky.webp';
import tascs from '../images/project/Tasc.webp';
import maruthampulam from '../images/project/pathirakali.webp';
import wisefin from '../images/project/Wisefin.webp';
// import wisefin from '../images/project/wisefin.png';
import lminternational from '../images/project/lminternational.webp';
import vcc from '../images/project/varanycentralcollege.webp';
import ebpo from '../images/project/e-bpo.webp';
import issv from '../images/project/IDAIKKURICHCHI_SSV.webp';
import retreat from '../images/project/retreat.png';
import quest from '../images/project/quest_frankston.webp';
import unibay from '../images/project/Unibay.webp';
import siddiveram from '../images/project/siddiveram-temple.webp';
import integrated from '../images/project/integrated.webp';
import gilbert from '../images/project/Gilbert_Vincent_Shop.webp';
import vallipuram from '../images/project/Sri_Vallipura_Aalwar.webp';
import nets from '../images/project/NETS_Digital_Solutions.webp';
import ulIT from '../images/project/ul_it_solutions.webp';
import itwoi from '../images/project/itwoi_warranty.webp';
import mvcc from '../images/project/mvcc.webp';
import redex from '../images/project/redex.webp';
import zineflix from '../images/project/zineflix.jpeg';
import vendormart from '../images/project/vendormart.jpg';
import saras from '../images/project/saras-services.webp';
import sannithi from '../images/project/sannithi.jpg';
import redexmobile from '../images/project/redexmobile.jpg';
import betterhalfmobile from '../images/project/betterhalf_mobile.png';
import betterhalf from '../images/project/betterhalf.webp';
import cinebooking from '../images/project/cinebooking.webp';



import ps1img1 from '../images/project-single/1.jpg';
import ps1img2 from '../images/project-single/2.jpg';
import ps1img3 from '../images/project-single/3.jpg';
import ps1img4 from '../images/project-single/4.jpg';
import ps1img5 from '../images/project-single/1.jpg';
import ps1img6 from '../images/project-single/2.jpg';
import ps1img7 from '../images/project-single/3.jpg';
import ps1img8 from '../images/project-single/5.jpg';
import ps1img9 from '../images/project-single/6.jpg';

import psub1img1 from '../images/project-single/p1.jpg';
import psub1img2 from '../images/project-single/p2.jpg';

const Projects = [
    {
        Id: '1',
        slug: 'zineflix',
        pImg: zineflix,
        ps1img: ps1img1,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'ZINEFLIX - GERMANY',
        subTitle: 'Web Design & Web Development',
        purl: 'https://zineflix.com/',
        order: 2
    },

    {
        Id: '2',
        slug: 'ul-it-solutions',
        pImg: ulIT,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'UL IT SOLUTIONS - GERMANY',
        subTitle: 'Web Design',
        purl: 'https://ulitsolutions.de/',
        order: 3
    },

    {
        Id: '3',
        slug: 'nets-digital-solutions',
        pImg: nets,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'NETS DIGITAL SOLUTIONS - UK',
        subTitle: 'Web Design',
        purl: 'https://netsdigitalsolutions.uk/',
        order: 4
    },

    {
        Id: '4',
        slug: 'saras-services',
        pImg: saras,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'SARAS SERVICES - GERMANY',
        subTitle: 'Web Design',
        purl: 'https://saras-services.com/en/saraenglsih/',
        order: 5
    },

    {
        Id: '5',
        slug: 'redex',
        pImg: redex,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'REDEX - Saudi Arabia',
        subTitle: 'Web Design',   
        order: 6
    },

    {
        Id: '6',
        slug: 'vendormart',
        pImg: vendormart,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Vendor Mart - SRI LANKA',
        subTitle: 'Web Design & Web Development', 
        order: 7  
    },

    {
        Id: '7',
        slug: 'wisefin',
        pImg: wisefin,
        ps1img: ps1img2,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'WISEFIN - AUSTRALIA',
        subTitle: 'Web Design & SEO',
        purl: 'https://wisefin.com.au/',
        order: 9
    },

    {
        Id: '8',
        slug: 'selvasannithi',
        pImg: sannithi,
        ps1img: ps1img2,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'SELVA SANNITHI - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://selvasannithi.com/',
        order: 10
    },

    {
        Id: '9',
        slug: 'e-bpo',
        pImg: ebpo,
        ps1img: ps1img8,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'E-BPO  - SRILANKA',
        subTitle: 'Web Design & Seo',
        purl: 'https://e-bpo.net/',
        order: 11
    },

    {
        Id: '10',
        slug: 'tasc',
        pImg: tascs,
        ps1img: ps1img3,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'TASC - AUSTRALIA',
        subTitle: 'SEO',
        purl: 'https://tascs.com.au/',
        order: 12
    },

    {
        Id: '11',
        slug: 'maruthampulam',
        pImg: maruthampulam,
        ps1img: ps1img6,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'MARUTHAMPULAM - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://pathirakali.org/',
        order: 13
    },

    {
        Id: '12',
        slug: 'murrindindi',
        pImg: retreat,
        ps1img: ps1img4,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'MURRINDINDI - AUSTRALIA ',
        subTitle: 'SEO',
        purl: 'https://murrindindiretreat.com.au/',
        order: 14
    },

    {
        Id: '13',
        slug: 'itwoi-warranty',
        pImg: itwoi,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'iTWOi international - SRILANKA',
        subTitle: 'Web Development',
        purl: 'https://itwoiinternational.lk/',
        order: 15
    },

    {
        Id: '14',
        slug: 'quest-frankston',
        pImg: quest,
        ps1img: ps1img7,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'QUEST FRANKSTON - AUSTRALIA ',
        subTitle: 'Social Media Marketing ',
        order: 16
    },

    {
        Id: '15',
        slug: 'mvcc',
        pImg: mvcc,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'MEESALAI VEERASINGAM CENTRAL COLLEGE - SRILANKA',
        subTitle: 'Web Design',
        order: 17
        
    },

    {
        Id: '16',
        slug: 'unibay',
        pImg: unibay,
        ps1img: ps1img5,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'UNIBAY - SRILANKA',
        subTitle: 'Web Design & SEO',
        purl: 'https://unibay.lk/',
        order: 18
    },

    {
        Id: '17',
        slug: 'gilbert-vincent-shop',
        pImg: gilbert,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Gilbert Vincent - SWITZERLAND',
        subTitle: 'Web Design & Web Development',
        order: 19
    },

    {
        Id: '18',
        slug: 'sri-vallipuram-aalwar',
        pImg: vallipuram,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'SRI VALLIPURAM AALWAR - SRILANKA',
        subTitle: 'Web Design',
        order: 20
    },

    {
        Id: '19',
        slug: 'varany-college',
        pImg: vcc,
        ps1img: ps1img7,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'VARANY COLLEGE - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://varanycentralcollege.lk/',
        order: 21
    },

    {
        Id: '20',
        slug: 'siddiveram-temple',
        pImg: siddiveram,
        ps1img: ps1img8,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'SIDDIVERAM TEMPLE - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://siddiveram.com/',
        order: 22
    },
  
    {
        Id: '21',
        slug: 'issv',
        pImg: issv,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'ISSV - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://idaikkurichchissv.lk/',
        order: 23
    },

    {
        Id: '22',
        slug: 'LM-international',
        pImg: lminternational,
        ps1img: ps1img6,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'LM_INTERNATIONAL - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://lminternational.lk/',
        order: 24
    },

    {
        Id: '23',
        slug: 'lucky-mobile',
        pImg: luckymobile,
        ps1img: ps1img1,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'LUCKY MOBILE - SRILANKA',
        subTitle: 'Web Design',
        purl: 'https://luckymobile.lk/',
        order: 25
    },
       
    {
        Id: '24',
        slug: 'integrated-corporate-advisory',
        pImg: integrated,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'INTEGRATED CORPORATE ADVISORY - SRILANKA',
        subTitle: 'Social Media Marketing',
        order: 26
    },

    {
        Id: '25',
        slug: 'scl-mobile',
        pImg: redexmobile,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'SCL MOBILE APP',
        subTitle: 'Mobile App Development',
        order: 27
    },

    {
        Id: '26',
        slug: 'betterhalf-mobile',
        pImg: betterhalfmobile,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Betterhalf MOBILE APP',
        subTitle: 'Mobile App Development',
        order: 28
    },  
    
    {
        Id: '27',
        slug: 'betterhalf',
        pImg: betterhalf,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Betterhalf - Sri Lanka',
        subTitle: 'Web Design and Web Development',
        purl: 'https://betterhalf.lk/',
        order: 1
    },   

    {
        Id: '28',
        slug: 'cinebooking',
        pImg: cinebooking,
        ps1img: ps1img9,
        psub1img1: psub1img1,
        psub1img2: psub1img2,
        title: 'Cinebooking - SRI LANKA',
        subTitle: 'Web Design & Web Development', 
        purl: 'https://cinebooking.lk/',
        order: 8  
    },

];

export default Projects;
