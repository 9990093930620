import simg from '../images/service/1.webp';
import simg2 from '../images/service/2.webp';
import simg3 from '../images/service/3.webp';
import simg4 from '../images/service/4.webp';

import sImgS1 from '../images/service-single/img-1.jpg';
import sImgS2 from '../images/service-single/img-2.jpg';
import sImgS3 from '../images/service-single/img-3.jpg';
import sImgS4 from '../images/service-single/img-4.jpg';

import sSingleimg1 from '../images/service-single/2.jpg';
import sSingleimg2 from '../images/service-single/3.jpg';

import Seo from '../components/services/Seo';
import WebDesignService from '../components/services/WebDesignService';
import PpcMarketing from '../components/services/PpcMarketing';
import SocialMediaMarketing from '../components/services/SocialMediaMarketing';
import WebDevelopmentService from '../components/services/WebDevelopmentService';
import SoftwareDevelopment from '../components/services/SoftwareDevelopment';
import DigitalMarketingService from '../components/services/DigitalMarketing';
import ITOutSourcing from '../components/services/ITOutSourcing';
import MobileAppDevelopment from '../components/services/MobileAppDevelopment';
import ITServicesConsulting from '../components/services/ITServicesConsulting';
import SystemMonitoringVisualization from '../components/services/SystemMonitoringVisualization';



const Services = [
    {
        Id: '1',
        slug: 'Website-Designing',
        sImg: simg2,
        sImgS: sImgS2,
        sTitle: 'Website Designing',
        sBreadCrumb: 'Web Design in Sri Lanka',
        description:
            'In the digital world, websites are crucial for businesses. Customers research online before service or purchase. A professional website prevents frustration. Enhance online visibility with expert web design company Sri Lanka, exceeding customer expectations',
        des2: 'In the digital world now websites become the backbone of business. Nowadays customers always do research on the internet before making a purchase approach for service. Without a professional website, customers .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-team',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <WebDesignService />,
    },

    {
        Id: '2',
        slug: 'Website-Development',
        sImg: simg2,
        sImgS: sImgS2,
        sTitle: 'Website Development',
        sBreadCrumb: 'Web Development in Sri Lanka',
        description:
            'Web development is the process of creating, enhancing, and maintaining websites or web applications through collaborative efforts. At its core is web design, ensuring user-centric interfaces with both visually impressive and smooth navigation. ',
        des2: 'Web development is the process of creating, enhancing, and maintaining websites or web applications through collaborative efforts. At its core is web design, ensuring user-centric interfaces with both visually impressive and smooth navigation. ',
        des3: 'Web development is the process of creating, enhancing, and maintaining websites or web applications through collaborative efforts. At its core is web design, ensuring user-centric interfaces with both visually impressive and smooth navigation. ',
        icon: 'flaticon-team',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <WebDevelopmentService />,
    },

    {
        Id: '3',
        slug: 'Search-Engine-Optimization(SEO)',
        sImg: simg,
        sImgS: sImgS1,
        sTitle: 'SEO',
        sBreadCrumb: 'SEO in Sri Lanka',
        description:
            'Search Engine Optimization is fundamental to digital marketing. Your page position in search results decides the traffic to your website. As SEO service providers we will make sure your business page shows up above your competitors in search results.',
        des2: 'Search Engine Optimization is fundamental to digital marketing. Your page position in search results decides the traffic to your website. As SEO service providers we will make sure your business page shows up above your competitors in search results.',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-advisor',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <Seo />,
    },

    {
        Id: '4',
        slug: 'Software-Development',
        sImg: simg,
        sImgS: sImgS1,
        sTitle: 'Software Development',
        sBreadCrumb: 'Software Development in Sri Lanka',
        description:
            'Software development is a process that involves requirements gathering, designing, testing, and maintaining computer programs and applications. It begins with requirements analysis, where the needs and specifications of the software are identified.',
        des2: 'Software development is a process that involves requirements gathering, designing, testing, and maintaining computer programs and applications. It begins with requirements analysis, where the needs and specifications of the software are identified.',
        des3: 'Software development is a process that involves requirements gathering, designing, testing, and maintaining computer programs and applications. It begins with requirements analysis, where the needs and specifications of the software are identified.',
        icon: 'flaticon-advisor',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <SoftwareDevelopment />,
    },

    {
        Id: '5',
        slug: 'Social-Media-Marketing',
        sImg: simg4,
        sImgS: sImgS4,
        sTitle: 'Social Media Marketing',
        sBreadCrumb: 'Social Media Marketing in Sri Lanka',
        description:
            'Nearly 60% of the global population is active on social media and it’s free to join on almost all of the platforms. This makes social media one of the major platforms in digital marketing. Considering the capabilities of Social media, now the business can’t ignore their presence on social media.  ',
        des2: 'Nearly 60% of the global population is active on social media and it’s free to join on almost all of the platforms. This makes social media one of the major platforms in digital marketing.  .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-tax',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <SocialMediaMarketing />,
    },
   
    {
        Id: '6',
        slug: 'Digital-Marketing',
        sImg: simg3,
        sImgS: sImgS3,
        sTitle: 'Digital Marketing',
        sBreadCrumb: 'Digital Marketing in Sri Lanka',
        description:
            'Digital marketing is a powerful tool that can be used to market your business and allows interactions with clients. Ignoring digital marketing is like opening a business but not telling anyone about it. We are here to say to the world what you are doing.',
        des2: 'Digital marketing is a powerful tool that can be used to market your business and allows interactions with clients. Ignoring digital marketing is like opening a business but not telling anyone about it. We are here to say to the world what you are doing.',
        des3: 'PPC marketing allows for immediate results which continue to improve over time thanks to intelligent data and optimization.',
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <DigitalMarketingService />,
    },

    {
        Id: '7',
        slug: 'IT-Outsourcing',
        sImg: simg3,
        sImgS: sImgS3,
        sTitle: 'IT Outsourcing',
        sBreadCrumb: 'IT Outsourcing',
        description:
            'IT outsourcing service is tailored to meet the unique needs of business worldwide. With a team of highly skilled professionals and a proven track record of delivering high-quality projects, we help companies streamline their operations and achieve their development goals efficiently.',
        des2: 'IT outsourcing is like hiring external experts to take care of specific IT jobs for a company. This allows the company to focus on its strengths and main activities while letting specialists handle the technical aspects.',
        des3: 'IT outsourcing is like hiring external experts to take care of specific IT jobs for a company. This allows the company to focus on its strengths and main activities while letting specialists handle the technical aspects.',
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <ITOutSourcing />,
    },

    {
        Id: '8',
        slug: 'Mobile-App-Development',
        sImg: simg3,
        sImgS: sImgS3,
        sTitle: 'Mobile App Development',
        sBreadCrumb: 'Mobile App Development',
        description:
            'In today\'s digital age, mobile apps are crucial for making users happy and reaching more people with your business. Whether you\'re just starting fresh or have been around for a while, it\'s really important to understand the basics of making successful mobile apps.',
        des2: 'In today\'s digital age, mobile apps are crucial for making users happy and reaching more people with your business. Whether you\'re just starting or have been around for a while, it\'s really important to understand the basics of making mobile apps.',
        des3: 'In today\'s digital age, mobile apps are crucial for making users happy and reaching more people with your business. Whether you\'re just starting or have been around for a while, it\'s really important to understand the basics of making mobile apps.',
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <MobileAppDevelopment />,
    },

    {
        Id: '9',
        slug: 'IT-Services-Consulting',
        sImg: simg3,
        sImgS: sImgS3,
        sTitle: 'IT Services Consulting',
        sBreadCrumb: 'IT Services Consulting',
        description:
            'In the always-changing world of business technology, IT services consulting is crucial for keeping organizations up-to-date with the latest advancements. This guide covers IT needs, strategic planning, cybersecurity, and adopting new technologies for a comprehensive overview.',
        des2: 'In the always-changing world of business technology, IT services consulting is crucial for keeping organizations up-to-date with the latest advancements. This guide covers IT needs, strategic planning, cybersecurity, and adopting new technologies for a comprehensive overview.',
        des3: 'In the always-changing world of business technology, IT services consulting is crucial for keeping organizations up-to-date with the latest advancements. This guide covers IT needs, strategic planning, cybersecurity, and adopting new technologies for a comprehensive overview.',
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <ITServicesConsulting />,
    },

    {
        Id: '10',
        slug: 'System-Monitoring-Visualization',
        sImg: simg3,
        sImgS: sImgS3,
        sTitle: 'System Monitoring and Visualization',
        sBreadCrumb: 'System Monitoring and Visualization',
        description:
            'System Monitoring ensures systems remain healthy, efficient, and secure.Monitoring continuously tracks metrics like performance and security, while visualization offers easy-to-understand representations.',
        des2: 'System Monitoring ensures systems remain healthy, efficient, and secure.Monitoring continuously tracks metrics like performance and security, while visualization offers easy-to-understand representations.',
        des3: 'System Monitoring ensures systems remain healthy, efficient, and secure.Monitoring continuously tracks metrics like performance and security, while visualization offers easy-to-understand representations.',
        icon: 'flaticon-taxes',
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        body: <SystemMonitoringVisualization />,
    },
    
];

export default Services;
