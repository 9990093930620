// Webdesign.js
import React from 'react';
import AccordionFaq from './AccordionFaq';


const DigitalMarketingFaq = ({ serviceTitle }) => {
  const DigitalMarketingContent = [
    // ... web design content items
    {
        title: "What is digital marketing?",
        content: "Digital marketing is a strategic approach to promoting products or brands through online channels such as social media and search engines. Utilizing the power of the internet, businesses engage with a vast online audience, utilizing various digital tools and platforms to enhance brand visibility and connect with potential customers.",
    },

    {
      title: "Why is digital marketing important?",
      content: "Digital marketing is crucial for businesses as it enables them to reach a vast online audience, track the performance of marketing campaigns in real-time, and enhance brand loyalty. The dynamic nature of digital strategies empowers businesses to engage with customers, improve brand awareness, and adapt strategies based on real-time insights.",
    },

    
    {
      title: "What are the key components of digital marketing?",
      content: "The key components of digital marketing include SEO (Search Engine Optimization), which optimizes websites for search engines, social media marketing for audience engagement, content marketing for valuable content creation, email marketing for targeted messaging, and PPC (Pay-Per-Click) advertising for quick traffic and conversions.",
    },

    {
      title: "How does content marketing benefit businesses?",
      content: " Content marketing benefits businesses by creating valuable content, such as blogs and videos, to attract and retain audiences. This strategy establishes authority within the industry, enhancing brand loyalty and driving customer action.",
    },

    {
      title: "What is email marketing?",
      content: "Email marketing involves sending targeted messages to communicate with customers, and drive conversions. By personalizing and segmenting emails, businesses enhance engagement rates, creating effective communication channels with their audience.",
    },

    {
      title: "What is PPC advertising.",
      content: "PPC advertising involves placing ads on search engines and other platforms, paying a fee each time the ad is clicked. This strategy offers a swift way to drive traffic and achieve measurable results, making it a valuable tool for businesses seeking quick and targeted online visibility.",
    },


    {
      title: "How does digital marketing adapt to trends?",
      content: "Digital marketing stays flexible by integrating emerging technologies like AI and chatbots, adjusting strategies based on analytics and evolving consumer behavior. This dynamic approach enables businesses to stay ahead of trends, ensuring relevance and effectiveness in a rapidly changing online landscape.",
    },

    {
      title:"Benefits of video marketing?",
      content: "Video marketing engages audiences visually on platforms like YouTube and TikTok, effectively conveying brand messages and enhancing communication. This dynamic medium allows businesses to connect with their audience in a captivating and memorable way, fostering brand awareness and creating a long-lasting impact.",
    },

    {
      title: "Why is Voice Search Optimization important?",
      content: "Voice Search Optimization is crucial as it tailors content for voice searches, aligning with evolving user behaviors and preferences. By adapting strategies to provide to voice-activated queries, businesses ensure accessibility and relevance, staying ahead in the digital landscape shaped by virtual assistants like Siri and Alexa.",
    },

    {
      title: "How to measure digital marketing success?",
      content: "Measuring digital marketing success involves tracking key performance indicators (KPIs) such as website traffic, conversion rates, click-through rates, and social media engagement. These metrics provide valuable insights for informed decision-making, allowing businesses to assess campaign performance, optimize strategies, and achieve overall success in the digital landscape..",
    },



  ];

  return <AccordionFaq serviceTitle={serviceTitle} content={DigitalMarketingContent} />;
};

export default DigitalMarketingFaq ;
