import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import About from '../../components/about/about';
import BlogSection from '../../components/BlogSection/BlogSection';
import CtaSection from '../../components/ctaSection/ctaSection';
import Footer2 from '../../components/footer2/Footer2';
import FunFact from '../../components/FunFact/FunFact';
import Hero from '../../components/hero/hero';
import Navbar3 from '../../components/Navbar3/Navbar3';
import ProjectSection from '../../components/ProjectSection/ProjectSection';
import ProjectSection3 from '../../components/ProjectSection3/ProjectSection3';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import PartnerSection from '../../components/PartnerSection/PartnerSection';
import ContactInfo from '../../components/ContactInfo/ContactInfo';
import abimg from '../../images/about.webp';
import Footer from '../../components/footer/Footer';

const HomePage = () => {
    return (
        <Fragment>
            <Helmet>
                <title>
                    Web Design in Sri Lanka
                </title>
                <meta
                    name="description"
                    content="Experience a transformative digital journey with web design sri Lanka, where elevation, innovation, and success come together seamlessly."
                />
            </Helmet>
            <Navbar3 topbarNone={'topbar-none'} />
            <Hero 
            heroslogan="Web design company in Sri Lanka"
            herotitle="Web sites build to boost your brands" 
            herocontent="We specialize in creating amazing websites, robust software applications and offer digital marketing solutions."/>
            <About 
            aboutTitle="Welcome To Matrix Mantra"
            abimg={abimg} 
            service="Web Design" 
            place="Sri Lanka"
            aboutcontent="Welcome to our creative hub! We specialize in turning ideas into amazing digital experiences, committed to not only making things look good but also ensuring seamless functionality. Explore the art of web design with us, where we extend our expertise to encompass web development, SEO, and digital marketing. Let's collaborate to transform your concepts into a comprehensive and successful digital presence. With our client’s feedbacks we can proudly say that we are one of the fast growing web design company in Sri Lanka and beyond."/>
            <ServiceSection />
            <CtaSection tagline="web design company in Sri Lanka."/>
            {/* <FunFact/> */}
            {/* <TeamSection/> */}
            <ProjectSection3 />
            <Testimonial />
            <BlogSection landingPage="web-design" />
            {/* <PartnerSection /> */}
            <ContactInfo />
            <Footer2 />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage;
