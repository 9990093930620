import React, { useEffect, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { getUserLocation, sendMail } from '../../utils/commonHelpers';
import './ContactForm.css'

const ContactForm = () => {
    const [, forceUpdate] = useState();
    const [validator] = useState(
        new SimpleReactValidator({
            className: 'errorMessage',
        })
    );

    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
    });
    const [submitEnabled, setSubmitEnabled] = useState(true);

    const changeHandler = (e) => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setSubmitEnabled(false);
        if (validator.allValid()) {
            validator.hideMessages();

            const userLocation = await getUserLocation();
            const locationString = `${userLocation.city}, ${userLocation.region}, ${userLocation.country_name}`;
            const formData = { ...forms, location: locationString };

            const submit = await sendMail(formData);
            if (submit) {
                setForms({
                    name: '',
                    email: '',
                    subject: '',
                    phone: '',
                    message: '',
                });
                setSubmitEnabled(true);
            } else {
                setSubmitEnabled(true);
            }
        } else {
            validator.showMessages();
            forceUpdate(1);
            setSubmitEnabled(true);
        }
    };

    return (
        <div className='contact-validation-active'>
            <h2 className='contact-heading'>Write Us a Message Here</h2>
            <form
            onSubmit={(e) => submitHandler(e)}
            className="form"
        >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name"
                        />
                        {validator.message(
                            'name',
                            forms.name,
                            'required|alpha_space'
                        )}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email"
                        />
                        {validator.message(
                            'email',
                            forms.email,
                            'required|email'
                        )}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your phone"
                        />
                        {validator.message('phone', forms.phone, 'phone')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.subject}
                            type="text"
                            name="subject"
                        >
                            <option value="" disabled>
                                Choose a Subject
                            </option>
                            <option value="Search Engine Optimization (SEO)">
                                Search Engine Optimization (SEO)
                            </option>
                            <option value="Website Designing">
                                Website Designing
                            </option>
                            <option value="Digital Marketing">
                                Digital Marketing
                            </option>
                            <option value="Social Media Marketing">
                                Social Media Marketing
                            </option>
                            <option value="Web Development">
                                Web Development
                            </option>
                            <option value="Others">Others</option>
                        </select>
                        {validator.message(
                            'subject',
                            forms.subject,
                            'required'
                        )}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message"
                    ></textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button
                    type="submit"
                    className="theme-btn"
                    disabled={!submitEnabled}
                >
                    Submit Now
                </button>
            </div>
        </form>
   
        </div>
     );    
};

export default ContactForm;
